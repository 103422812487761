import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'redux/store';
import AppContainer from 'containers/App/AppContainer';
import 'config/execute';
import 'creatella-react-components/lib/styles.scss';
import 'styles/styles.scss';
import ModalProvider from 'provider/modal/ModalProvider';

render(
    <Provider store={store}>
        <ModalProvider>
            <ConnectedRouter history={history}>
                <AppContainer />
            </ConnectedRouter>
        </ModalProvider>
    </Provider>,
    document.getElementById('root')
);
