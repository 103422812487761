import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import ptLogoHeader from 'assets/images/header/pt-logo-header.svg';
import closeImage from 'assets/images/general/gray-close.svg';

import hamburgerMenu from 'assets/images/header/hamburger.svg';

import bell from 'assets/images/header/bell.svg';
import avatar from 'assets/images/header/avatar.svg';
import arrowCircle from 'assets/images/header/arrow-circle.svg';

import classNames from 'classnames';
import './HeaderNav.scss';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { NOTIF_MODAL_STATUS, PROFILE_MENU_STATUS, CANCEL_MENU_STATUS, ROUTES, USER_ROLES, ORDER_MENU_STATUS } from 'config/constants';
import { handleRefreshUserProfile, updateLockUser } from 'redux/reducers/auth';
import { showCreateOrderModal } from 'redux/reducers/order';
import { connect } from 'react-redux';
import Notification from '../Notification/Notification';
import Nudge from 'containers/App/components/Nudge/Nudge';
import ProfileMenu from '../ProfileMenu/ProfileMenu';
import CancelMenu from '../CancelMenu/CancelMenu';
import { showCancelModal } from 'redux/reducers/cancelOrder';
import OrderMenu from '../OrderMenu/OrderMenu';
import ConfirmModal, { CONFIRM_MODAL_DEFAULT_STATE } from 'screens/ConfirmModal/ConfirmModal';
import { useOnClickOutside } from 'utils/helpers';
import { updateBackdrop } from 'redux/reducers/settings';

const ptLogoSingle = ptLogoHeader;

HeaderNav.propTypes = {
    createOrder: PropTypes.func.isRequired,
    showCancelModal: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
    refreshProfile: PropTypes.func.isRequired,
    LockUser: PropTypes.func.isRequired,
    updateBackdrop: PropTypes.func.isRequired
};

function HeaderNav({ createOrder, showCancelModal, profile, refreshProfile, LockUser, updateBackdrop }) {
    const isTutorialFirstStep = profile.is_trader &&
    profile.trader.company.is_demo &&
    profile.tutorial_step === null;

    const history = useHistory();
    const location = useLocation();
    const [notifModalStatus, setNotifModalStatus] = useState(NOTIF_MODAL_STATUS.HIDDEN);
    const [profileMenuStatus, setProfileMenuStatus] = useState(PROFILE_MENU_STATUS.HIDDEN);
    const [cancelMenuStatus, setCancelMenuStatus] = useState(CANCEL_MENU_STATUS.HIDDEN);
    const [orderMenuStatus, setOrderMenuStatus] = useState(ORDER_MENU_STATUS.HIDDEN);
    const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(isTutorialFirstStep);
    const [cancelCompanyOrders, setCancelCompanyOrders] = useState(false);

    const bellButtonRef = useRef();
    const profileLinkRef = useRef();
    const cancelButtonRef = useRef();
    const orderLinkRef = useRef();
    const sidebarRef = useRef();

    const onLogoClick = () => {
        history.replace(ROUTES.HOME);
    };

    const openHamburgerMenu = () => {
        setIsHamburgerMenuOpen(true);
    };

    const closeHamburgerMenu = () => {
        setIsHamburgerMenuOpen(false);
        updateBackdrop(false);
    };

    const onClickToggleNotifs = () => {
        if (notifModalStatus === NOTIF_MODAL_STATUS.HIDDEN) {
            setNotifModalStatus(NOTIF_MODAL_STATUS.VISIBLE);
            updateBackdrop(true);
        } else {
            setNotifModalStatus(NOTIF_MODAL_STATUS.HIDDEN);
            updateBackdrop(false);
        }
    };

    const onClickToggleProfileMenu = () => {
        if (profileMenuStatus === PROFILE_MENU_STATUS.HIDDEN) {
            setProfileMenuStatus(PROFILE_MENU_STATUS.VISIBLE);
            updateBackdrop(true);
        } else {
            setProfileMenuStatus(PROFILE_MENU_STATUS.HIDDEN);
            updateBackdrop(false);
        }
    };

    const onClickToggleOrderMenu = () => {
        if (orderMenuStatus === ORDER_MENU_STATUS.HIDDEN) {
            setOrderMenuStatus(ORDER_MENU_STATUS.VISIBLE);
            updateBackdrop(true);
        } else {
            setOrderMenuStatus(ORDER_MENU_STATUS.HIDDEN);
            updateBackdrop(false);
        }
    };

    const onClickCancelOrderButton = () => {
        if (cancelMenuStatus === CANCEL_MENU_STATUS.HIDDEN) {
            setCancelMenuStatus(CANCEL_MENU_STATUS.VISIBLE);
            updateBackdrop(true);
        } else {
            setCancelMenuStatus(CANCEL_MENU_STATUS.HIDDEN);
            updateBackdrop(false);
        }

        setCancelCompanyOrders(false);
    };

    useOnClickOutside(sidebarRef, () => {
        if (isHamburgerMenuOpen && !isTutorialFirstStep) {
            closeHamburgerMenu();
        }
    });

    const userObj = profile.role_type === USER_ROLES.TRADER ? profile.trader
        : profile.role_type === USER_ROLES.MO ? profile.middle_office
            : profile.role_type === USER_ROLES.BROKERAGE ? profile.broker
                : profile.role_type === USER_ROLES.BROKERCOMPANY ? profile.bad
                    : profile.role_type === USER_ROLES.ADMIN ? {}
                        : {};

    const handleCloseConfirmModal = () => {
        setConfirmModalStatus(CONFIRM_MODAL_DEFAULT_STATE);
    };

    const [confirmModalStatus, setConfirmModalStatus] = useState({
        type: null,
        isVisible: false,
        handleClose: null,
        handleSubmit: null,
        isProcessing: false,
        title: ''
    });

    const onLockClick = () => {
        setProfileMenuStatus(PROFILE_MENU_STATUS.HIDDEN);
        setConfirmModalStatus({
            title: 'Lock screen',
            message: 'Do you want to lock your screen?',
            yesButtonText: 'Yes, lock it',
            noButtonText: 'No',
            yesButtonColor: 'green',
            isVisible: true,
            handleClose: handleCloseConfirmModal,
            handleSubmit: handleLockScreen(),
            isProcessing: false,
            wide: true
        });
    };

    const handleLockScreen = () => () => {
        LockUser(true);
        setConfirmModalStatus(CONFIRM_MODAL_DEFAULT_STATE);
    };

    const getManagementPath = () => {
        switch (profile.role_type) {
            case USER_ROLES.ADMIN:
                return ROUTES.MANAGEMENT_PTAUSERS;
            case USER_ROLES.TRADER:
                return ROUTES.MANAGEMENT_TM_TRADERS;
            case USER_ROLES.BROKERCOMPANY:
                return ROUTES.MANAGEMENT_BADUSERS_BROKERS;
            case USER_ROLES.MO:
                return ROUTES.MANAGEMENT_USERS_TRADERS;
            case USER_ROLES.DATA_USER:
                return ROUTES.MANAGEMENT_PACKAGE;
            default:
                break;
        }
    };

    return (
        <>
            <div className='header-nav'>
                <div className='header-nav__icons'>
                    <img onClick={openHamburgerMenu} className='header-nav__hamburger-button' src={hamburgerMenu}
                        alt='menu' />
                    <img onClick={onLogoClick} className='header-nav__logo' src={ptLogoHeader}
                        alt='paper trader' />
                </div>

                <div className='header-nav__nav'>
                    <div ref={sidebarRef} className={classNames('header-nav__links-wrapper', { 'header-nav__links-wrapper--active': isHamburgerMenuOpen })}>
                        <img onClick={closeHamburgerMenu} className='header-nav__close-sidebar' src={closeImage}
                            alt='close' />

                        <img onClick={onLogoClick} className='header-nav__single-logo' src={ptLogoSingle}
                            alt='paper trader' />
                        <Link
                            onClick={closeHamburgerMenu}
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname === ROUTES.HOME })}
                            to={{
                                pathname: ROUTES.HOME,
                                state: { qoutes: true }
                            }}>{profile.role_type === USER_ROLES.DATA_USER ? 'Prices' : 'Quotes'}</Link>
                        {profile.is_trader || profile.is_broker ? <Link
                            onClick={closeHamburgerMenu}
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname === ROUTES.NEGOTIATION })}
                            to={{
                                pathname: ROUTES.NEGOTIATION,
                                state: {}
                            }}>Negotiations <Nudge /></Link> : null}
                        {profile.is_trader || profile.is_broker ? <Link
                            onClick={closeHamburgerMenu}
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname === ROUTES.INDICATIONS })}
                            to={{
                                pathname: ROUTES.INDICATIONS,
                                state: {}
                            }}>My Indications</Link> : null}

                        {profile.role_type !== USER_ROLES.DATA_USER &&
                        <Link
                            onClick={closeHamburgerMenu}
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname === ROUTES.ORDER_BOOK })}
                            to={{
                                pathname: ROUTES.ORDER_BOOK,
                                state: { qoutes: true }
                            }}>Order Book</Link>
                        }

                        {profile.role_type === USER_ROLES.BROKERAGE || profile.role_type === USER_ROLES.BROKERCOMPANY || profile.role_type === USER_ROLES.TRADER || profile.role_type === USER_ROLES.MO ? <Link
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname === ROUTES.REPORTS })}
                            onClick={closeHamburgerMenu}
                            to={{
                                pathname: ROUTES.REPORTS,
                                state: { qoutes: true }
                            }}>Risk Analysis</Link> : null}
                        {profile.role_type === USER_ROLES.ADMIN || profile.is_bad || (profile.is_trader && profile.trader.is_tm) || profile.is_middle_office || profile.role_type === USER_ROLES.DATA_USER ? <Link
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname.includes(ROUTES.MANAGEMENT) })}
                            onClick={closeHamburgerMenu}
                            to={{
                                pathname: getManagementPath(),
                                state: {}
                            }}>Management</Link> : null}
                        {profile.is_trader || profile.is_broker
                            ? <div className='header-nav__link header-nav__link--order-button'>
                                <Link
                                    ref={orderLinkRef}
                                    className='header-nav__link header-nav__link--button tutorial-tour__new-order'
                                    onClick={onClickToggleOrderMenu}
                                    to={{}}>New Order</Link>

                                <OrderMenu
                                    closeHamburgerMenu={closeHamburgerMenu}
                                    createOrder={createOrder}
                                    updateBackdrop={updateBackdrop}
                                    orderLinkRef={orderLinkRef} orderMenuStatus={orderMenuStatus}
                                    setOrderMenuStatus={setOrderMenuStatus} />

                            </div> : null}
                        {/* {profile.is_broker ? <Link
                            className='header-nav__link header-nav__link--button'
                            onClick={() => {
                                createOrder();
                                closeHamburgerMenu();
                            }}

                            to={{}}>New Order</Link> : null} */}
                        {profile.is_trader || profile.is_middle_office ? <div className='header-nav__link  header-nav__link--cancel-button'>
                            <Link
                                ref={cancelButtonRef}
                                className='header-nav__cancel-button'
                                onClick={onClickCancelOrderButton}
                                to={{}}>Withdraw Orders</Link>
                            {profile.is_trader || profile.is_middle_office ? <CancelMenu showCancelModal={showCancelModal} cancelButtonRef={cancelButtonRef}
                                cancelMenuStatus={cancelMenuStatus}
                                profile={profile}
                                updateBackdrop={updateBackdrop}
                                cancelCompanyOrders={cancelCompanyOrders}
                                setCancelCompanyOrders={setCancelCompanyOrders}
                                setCancelMenuStatus={setCancelMenuStatus}
                                closeHamburgerMenu={closeHamburgerMenu} /> : null}
                        </div> : null}

                        {profile.role_type === USER_ROLES.DATA_USER &&
                        <Link
                            onClick={closeHamburgerMenu}
                            className={classNames('header-nav__link', { 'header-nav__link--active': location.pathname === ROUTES.DATA_API })}
                            to={{
                                pathname: ROUTES.DATA_API
                            }}>Data API</Link>
                        }
                    </div>
                    <div className='header-nav__actions-wrapper'>
                        <div className='header-nav__notif-wrapper'>
                            {profile.role_type !== USER_ROLES.DATA_USER &&
                            <div ref={bellButtonRef} onClick={onClickToggleNotifs} className='header-nav__notif-link'>

                                <img src={bell} alt='notifications' />
                                {profile.total_unread_notifications > 0 ? <div className='header-nav__notif-counter'>{profile.total_unread_notifications}</div> : null}
                            </div>
                            }
                            <Notification updateBackdrop={updateBackdrop} bellButtonRef={bellButtonRef} notifModalStatus={notifModalStatus}
                                setNotifModalStatus={setNotifModalStatus}
                                refreshProfile={refreshProfile} />
                        </div>
                        <div className='header-nav__profile-details' >
                            <div ref={profileLinkRef} onClick={onClickToggleProfileMenu} className='header-nav__profile-details-link'>
                                <div className='header-nav__profile-name'>{profile.name}</div>
                                <div className='image-cropper'>
                                    <img src={userObj.photo ? userObj.photo : avatar} alt='user avatar' />
                                </div>
                                <img className='header-nav__arrow-circle' src={arrowCircle} alt='open menu' />
                            </div>

                            <ProfileMenu
                                role={profile.role_type}
                                updateBackdrop={updateBackdrop}
                                profileLinkRef={profileLinkRef} profileMenuStatus={profileMenuStatus}
                                setProfileMenuStatus={setProfileMenuStatus} onLockClick={onLockClick} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='lock-modal'>
                <ConfirmModal
                    type={confirmModalStatus.type}
                    isVisible={confirmModalStatus.isVisible}
                    handleClose={confirmModalStatus.handleClose}
                    handleSubmit={confirmModalStatus.handleSubmit}
                    isProcessing={confirmModalStatus.isProcessing}
                    title={confirmModalStatus.title}
                    message={confirmModalStatus.message}
                    yesButtonColor={confirmModalStatus.yesButtonColor}
                    yesButtonText={confirmModalStatus.yesButtonText}
                    noButtonText={confirmModalStatus.noButtonText}
                    wide={confirmModalStatus.wide}
                    superWide={confirmModalStatus.superWide}
                    multiLine={confirmModalStatus.multiLine} />
            </div>
        </>
    );
}

const mapStateToProps = ({ auth }) => {
    const { profile } = auth;

    return {
        profile
    };
};

function mapDispatchToProps(dispatch) {
    return {
        createOrder: (isBlock = false) => {
            dispatch(showCreateOrderModal('', '', isBlock));
        },
        showCancelModal: (type, companyOrders) => {
            dispatch(showCancelModal(type, companyOrders));
        },
        refreshProfile: () => {
            dispatch(handleRefreshUserProfile());
        },
        LockUser: e => {
            dispatch(updateLockUser(e));
        },
        updateBackdrop: state => {
            dispatch(updateBackdrop(state));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderNav);
