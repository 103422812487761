/****
 *
 * window & process.env constants
 *
 */
export const AUTH_ELOQUENT = window.AuthEloquent;
export const TRADING_ELOQUENT = window.TradingEloquent;
export const AXIOS = window._axios;
export const TRADING_AXIOS = window._tradingAxios;
// see https://github.com/SchwSimon/creatella-react-components#withasynccaller
window._withAsyncCallerAxios = window._TradingBaseAxios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;
export const TRADE_API_URL = process.env.REACT_APP_TRADE_API_URL;
export const PUSHER_KEY = process.env.REACT_APP_PUSHER_KEY;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'skrmd-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'skrmd-appLanguage';
export const STORAGEKEY_DEVICE_ID = 'userrmd-BE-device-id';
export const STORAGEKEY_AFTER_LOGOUT_MSG = 'urmd-After-lg-msg';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en'; // TODO_REPLACE(en | de | fr | etc..)

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: ''
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    // google: {
    //     families: [
    //         'Poppins:300',
    //         'Poppins:400',
    //         'Poppins:500',
    //         'Poppins:700',
    //         'Poppins:900'
    //     ]
    // }
};

// Social networks config
export const SOCIAL_NETWORK_URLS = {
    FACEBOOK: 'TODO_REPLACE',
    INSTAGRAM: 'TODO_REPLACE',
    TWITTER: 'TODO_REPLACE',
    LINKEDIN: 'TODO_REPLACE'
    // ...
};

export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    RECOVER_PASSWORD: '/recover',
    RESET_PASSWORD: '/reset',
    SET_PASSWORD: '/set-password',
    ORDER_BOOK: '/order-book',
    NEGOTIATION: '/negotiation',
    _TESTING: '/testing',

    SETTINGS: '/settings',
    SETTINGS_TRADER_PROFILE: '/settings/trader/profile',
    SETTINGS_NOTIFICATIONS: '/settings/notifications',
    SETTINGS_LIMITS: '/settings/limits',
    SETTINGS_LIMITS_MY: '/settings/limits/my',
    SETTINGS_LIMITS_COMPANY: '/settings/limits/company',
    SETTINGS_LIMITS_COMPANY_TOTAL: '/settings/limits/company/total',
    SETTINGS_LIMITS_COMPANY_PRODUCT: '/settings/limits/company/product',
    SETTINGS_LIMITS_COUNTERPARTY: '/settings/limits/counterparty',
    SETTINGS_LIMITS_COUNTERPARTY_TOTAL: '/settings/limits/counterparty/total',
    SETTINGS_LIMITS_COUNTERPARTY_PRODUCT: '/settings/limits/counterparty/product',
    SETTINGS_BROKERAGE: '/settings/brokerage',
    SETTINGS_MO_PROFILE: '/settings/mo/profile',
    SETTINGS_BROKER_PROFILE: '/settings/broker/profile',
    SETTINGS_BAD_CLIENTS: '/settings/bad/clients',
    SETTINGS_BAD_PRODUCTS: '/settings/bad/products',
    SETTINGS_BAD_PROFILE: '/settings/bad/profile',
    REGISTRATION: '/registration',

    MANAGEMENT: '/management',
    MANAGEMENT_TM_TRADERS: '/management/tm/traders',
    MANAGEMENT_USERS: '/management/users',
    MANAGEMENT_USERS_TRADERS: '/management/users/traders',
    MANAGEMENT_USERS_MOS: '/management/users/mos',
    MANAGEMENT_COMPANYDETAILS: '/management/company-details',
    MANAGEMENT_EXTERNALTRADE: '/management/external-trade',

    MANAGEMENT_LIMITS: '/management/limits',
    MANAGEMENT_LIMITS_COMPANY: '/management/limits/company',
    MANAGEMENT_LIMITS_COMPANY_TOTAL: '/management/limits/company/total',
    MANAGEMENT_LIMITS_COMPANY_PRODUCT: '/management/limits/company/product',
    MANAGEMENT_LIMITS_COMPANY_TRADERS: '/management/limits/company/traders',
    MANAGEMENT_LIMITS_COUNTERPARTY: '/management/limits/counterparty',
    MANAGEMENT_LIMITS_COUNTERPARTY_TOTAL: '/management/limits/counterparty/total',
    MANAGEMENT_LIMITS_COUNTERPARTY_PRODUCT: '/management/limits/counterparty/product',
    MANAGEMENT_BROKERAGE: '/management/brokerage',

    MANAGEMENT_BADUSERS: '/management/bad-users',
    MANAGEMENT_BADUSERS_BROKERS: '/management/bad-users/brokers',
    MANAGEMENT_BADUSERS_ADMINS: '/management/bad-users/admins',
    MANAGEMENT_BADPRODUCTS: '/management/bad-products',
    MANAGEMENT_BADCLIENTS: '/management/bad-clients',

    MANAGEMENT_PTAUSERS: '/management/pta-users',
    MANAGEMENT_PTACOMPANIES: '/management/pta-companies',
    MANAGEMENT_PTALAP: '/management/pta-lap',
    MANAGEMENT_PTAADMINS: '/management/pta-admins',
    MANAGEMENT_PTAPRODUCTS: '/management/pta-products',
    MANAGEMENT_TRADEABLES: '/management/tradeables',

    REPORTS: '/risk-analysis',
    CONFIRM: '/confirm',
    BULK: '/bulk',
    INDICATIONS: '/indications',

    DATA_API: '/data-api',
    MANAGEMENT_PACKAGE: '/management/package',
    SETTINGS_DATAUSER_PROFILE: '/settings/data-user/profile',
    NEW_REGISTRATION: '/live-registration',
    NEW_LOGIN: '/new-login',
    SIGN_UP: '/signup',
    WELCOME_MO: '/onboarding-live-account'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.HOME;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.HOME;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.LOGIN;

/****
 *
 * Project config
 *
 */

// ...

export const STRONG_PASSWORD_RULES = {
    minLength: 8,
    minLowercase: 0,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
    returnScore: true
};
export const STRONG_PASSWORD_SCORE = 35;
export const MEDIUM_PASSWORD_SCORE = 20;

export const HOME_INIT_PRODUCTS = 50;

export const ORDER_TYPE = {
    BUY: 1,
    SELL: 2
};

export const NOTIF_MODAL_STATUS = {
    HIDDEN: 0,
    VISIBLE: 1,
    FULLSIZE: 2
};

export const PROFILE_MENU_STATUS = {
    HIDDEN: 0,
    VISIBLE: 1
};

export const CANCEL_MENU_STATUS = {
    HIDDEN: 0,
    VISIBLE: 1
};

export const CURRENCY_MODAL_STATUS = {
    HIDDEN: 0,
    VISIBLE: 1
};

export const ORDER_MENU_STATUS = {
    HIDDEN: 0,
    VISIBLE: 1
};

export const CANCEL_TYPE = {
    ALL_ORDERS: 0,
    ALL_SELL: 1,
    ALL_BUY: 2,
    ALL_NEGO: 3,
    SINGLE: 4
};

export const NOTIFICATION_TYPE = {
    USER_NOTIF: 'App\\Notifications\\UserNotification',
    USER_NUDGE: 'App\\Notifications\\NudgeSent',
    USER_NUDGE_PLAY_SOUND: 'App\\Notifications\\PlaySound', // this event is only for FE(custom)
    USER_NUDGE_ACKNOWLEDGED: 'App\\Notifications\\NegotiationNudgeAcknowledged',
    NEGO_MAKER_ACCEPT_FIRM: 'App\\Notifications\\MakerAcceptFirmRequest',
    NEGO_MAKER_ADD_QTY: 'App\\Notifications\\MakerAddedQuantity',
    NEGO_REJECT_FIRM: 'App\\Notifications\\MakerRejectFirmRequest',
    NEGO_CANCEL: 'App\\Notifications\\NegotiationCanceled',
    NEGO_COUNTER: 'App\\Notifications\\NegotiationCountered',
    NEGO_DONE: 'App\\Notifications\\NegotiationDone',
    NEGO_INIT: 'App\\Notifications\\NegotiationInitiated',
    NEGO_QTY_REQUEST: 'App\\Notifications\\QuantityRequested',
    NEGO_AMEND: 'App\\Notifications\\NegotiationAmended',
    PRODUCT_UPDATE_FOR_USER: 'App\\Notifications\\ProductUpdatedForUser',
    NEGO_ACK: 'App\\Notifications\\NegotiationDoneAcknowledged',
    USER_UPDATE: 'App\\Notifications\\UserSettingsUpdated',
    NEGO_FIRM_REQUESTED: 'App\\Notifications\\FirmRequested',
    NEW_MESSAGE_RECEIVED: 'App\\Notifications\\NewNegotiationMessageReceived',
    NEW_MESSAGE_SENT: 'App\\Notifications\\NegotiationMessageSent',
    LOG_GENERATED: 'App\\Notifications\\LogGenerated',
    MESSAGE_READ: 'App\\Notifications\\NegotiationMessageRead',
    NEGO_HAS_MESSAGE: 'App\\Notifications\\NegotiationHasNewMessage',
    COMPANY_LIMIT_UPDATE: 'App\\Notifications\\CompanyLimitUpdated',
    COMPANY_PRODUCT_LIMIT_UPDATE:
        'App\\Notifications\\CompanyProductTypeLimitUpdated',
    TRADER_LIMIT_UPDATE: 'App\\Notifications\\TraderLimitUpdated',
    COUNTERPARTY_LIMIT_UPDATE: 'App\\Notifications\\CounterpartyLimitUpdated',
    COUNTERPARTY_PRODUCT_LIMIT_UPDATE:
        'App\\Notifications\\CounterpartyProductTypeLimitUpdated',
    ORDER_UPDATED: 'App\\Notifications\\OrderUpdated',
    TRADER_UPDATED: 'App\\Notifications\\TraderUpdated',
    ACCOUNT_DEACTIVATE: 'App\\Notifications\\AccountDeactivated',
    BAD_UPDATED: 'App\\Notifications\\BadUpdated',
    BROKER_UPDATED: 'App\\Notifications\\BrokerUpdated',
    MO_UPDATED: 'App\\Notifications\\MiddleOfficeUpdated',
    PTA_UPDATED: 'App\\Notifications\\PTAdminUpdated',
    ORDERBOOK_UPDATED: 'App\\Notifications\\OrderBookUpdated',
    LOCKED_USER: 'App\\Notifications\\TokenLocked',
    UNLOCKED_USER: 'App\\Notifications\\TokenUnlocked',
    BROKERAGE_DEACTIVATED: 'App\\Notifications\\BrokerageDeactivated',
    BROKERAGE_ACTIVATED: 'App\\Notifications\\BrokerageActivated',
    BROKERAGE_UPDATED: 'App\\Notifications\\BrokerageUpdated', // this event triggers when mo change broker settings

    COMPANY_PRODUCT_DEACTIVATED:
        'App\\Notifications\\CompanyProductTypeDeactivated',
    COMPANY_PRODUCT_ACTIVATED:
        'App\\Notifications\\CompanyProductTypeActivated',
    CLIENT_DEACTIVATED: 'App\\Notifications\\ClientDeactivated',
    CLIENT_ACTIVATED: 'App\\Notifications\\ClientActivated',
    EXTERNAL_TRADES_MERGED: 'App\\Notifications\\ExternalTradesMerged',
    EXTERNAL_BROKERAGE_TRADES_MERGED: 'App\\Notifications\\ExternalBrokerageTradesMerged',
    BROKERAGE_PRODUCT_ACTIVATED: 'App\\Notifications\\BrokerageProductTypeActivated',
    BROKERAGE_PRODUCT_DEACTIVATED: 'App\\Notifications\\BrokerageProductTypeDeactivated',
    DEMO_COMPANY_APPROVED: 'App\\Notifications\\CompanyApproved'
};

export const BULK_INNER_NOTIFICATIONS = {
    BROKERAGES_UPDATED: 'BROKERAGES_UPDATED',
    BROKERAGE_REMOVED: 'BROKERAGE_REMOVED',
    PRODUCTS_UPDATED: 'PRODUCTS_UPDATED',
    PRODUCT_REMOVED: 'PRODUCT_REMOVED',
    CLIENT_DEACTIVATED: 'CLIENT_DEACTIVATED',
    CLIENTS_UPDATED: 'CLIENTS_UPDATED'

};

export const USER_TYPES = {
    TRADER: 'App\\Models\\Trader',
    BROKER: 'App\\Models\\Broker'
};

export const PUBLIC_EVENTS = {
    NEW_ORDER: 'NewOrderPlaced',
    ORDER_CANCEL: 'OrderCanceled',
    ORDER_UPDATE: 'OrderUpdated',
    ORDER_DONE: 'OrderDone',
    PRODUCT_UPDATED: 'ProductUpdated',
    TRADING_DAY_RESTARTED: 'TradingDayRestarted',
    ORDER_CANCELED_IN_BULK: 'OrderCanceledInBulk',
    PRODUCT_TYPE_CREATED: 'ProductTypeCreated',
    PRODUCT_TYPE_UPDATED: 'ProductTypeUpdated',
    PRODUCT_CREATED: 'ProductCreated'
};

export const DEMO_EVENTS = {
    LOAD_DEMO_ORDER: 'LoadDemoOrder',
    EXPAND_DEMO_ORDER: 'ExpandDemoOrder'
};

export const USER_ROLES = {
    TRADER: 1,
    BROKERAGE: 2,
    MO: 3,
    BROKERCOMPANY: 4,
    DATA_USER: 5,
    ADMIN: 99
};

export const COMPANY_TYPES = {
    TRADING_COMPANY: 1,
    BROKERAGE: 2,
    DATA_USER: 3
};

export const TENOR_LIMITS = [3, 6, 9, 12, 18, 24];

export const NUDGE_SECS = 20;

export const ORDER_STATUS = {
    UNDER_NEGOTIATION: 0,
    ACTIVE: 1,
    FILLED: 2
};

export const SETTING_SCREENS = {
    NOTIFICATION: 0,
    PROFILE: 1,
    MO_PROFILE: 3,
    PTA_USERS: 4,
    PTA_COMPANIES: 5,
    PTA_LAST_ASSESSED_PRICE: 6,
    COMPANY_DETAILS: 8,
    MO_USERS: 9,
    MO_MOS: 10,
    MO_TRADERS: 11,
    MO_LIMITS: 12,
    TOTAL_COMPANY_LIMITS: 13,
    TOTAL_PRODUCT_LIMITS: 14,
    MO_TRADER_LIMITS: 15,
    COUNTERPARTY_LIMITS: 16,
    COUNTERPARTY_PRODUCT_LIMITS: 17,
    TRADER_LIMITS: 18,
    MY_LIMITS: 19,
    MIDDLE_OFFICE: 20,
    ACTIVE_BROKERAGES: 21,
    BAD_PROFILE: 22,
    BAD_USERS: 23,
    BAD_BROKERS: 24,
    BAD_ADMINS: 25,
    BAD_CLIENTS: 26,
    BAD_PRODUCTS: 27,
    TM_TRADERS: 28,
    PTA_ADMINS: 30,
    MY_COMPANY_LIMITS: 31,
    TOTAL_COUNTERPARTY_LIMITS: 31,
    EXTERNAL_TRADES: 32
};

export const SELECTOR_TYPE = {
    COUNTERPARTIES: 0,
    BROKERAGES: 1,
    BROKERAGE_PRODUCTS: 2,
    MO_UNASSINGNED_PRODUCTS: 3,
    APPROVED_PRODUCTS: 4,
    PTA_ALL_PRODUCTS: 5,
    PRODUCT_TYPES: 6
};

export const USER_LOCK_TIME = 1000 * 60 * 30;

// "Staging_REACT_APP_URL":'https://471taq8qp6spvnmt-rmd.paper-trader.com/'
// "founder-staging_REACT_APP_URL":'https://dev.paper-trader.com'
// "platform":'https://platform.paper-trader.com/'
// "production_REACT_APP_URL":'https://paper-trader.com'
// docs :"https://471taq8qp6spvnmt-trading-api.paper-trader.com/docs"
// https://471taq8qp6spvnmt-api.paper-trader.com/vapor-ui/logs/queue?startTime=1645342548
// https://docs.google.com/spreadsheets/u/1/d/1whI6Gv2f_PosvmBsl15bct4wjm5hCpG-V1MOQK-KSP4/edit?authuser=1#gid=1939758836

// test users
// Trading company A
// MO: a-trading-company-mo@email.com (8gvNTJji)
// Trader: first-trader-a@email.com (FtoEZDKJz7)

// Trading company B
// MO: b-trading-company-mo@email.com (BIxp1eHw)
// Trader: first-trader-b@email.com (ocxov402G4)

// Brokerage company B

// Brokerage Admin: brokerage-company-b-bad@email.com (F5TLqbta)
// Broker 1: primary-broker-b@email.com (CCKw5mh7Ry)
// Broker 2: secondary-broker-b@email.com (QlZtlse7jg)

// admin : admin@example.com (123456)
